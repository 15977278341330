//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}


.heading {
	text-align: center;
	align-self: flex-start;
	margin-bottom: 0;
  }
  
  #videos{
	position: relative;
	height: 85vh;
	width: 88vw;
	margin: auto;
	align-self: flex-start;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
	justify-items: center;
	align-items: center;
  }
  
  .vid{
	position: relative;
	background-color:black;
	height: 95%;
	width: 95%;
	border-width: 1px;
	border-color: #38373A;
	border-style: solid;
  }
  
  .controls{
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	margin: -20px auto;
	display: grid;
	grid-template-columns: repeat(3, 33%);
	align-items: center;
	justify-items: center;
	z-index: 1;
	width: 500px;
	max-width: 60vw;
  }
  
  .controls p{
	padding: 10px;
	cursor: pointer;
	background: #38373A;
	color: #F7F7F7;
	border-width: 1px;
	border-color: #F7F7F7;
	border-style: solid;
  }
  
  .controls p.on{
	background: #F7F7F7;
	color: #38373A;
	border-width: 1px;
	border-color: #38373A;
	border-style: solid;
  }
  
  .join{
	position: absolute;
	z-index: 1;
	width: 30vw;
	height: fit-content;
	height: -moz-max-content;
	top: 50vh;
	left: 50vw;
	transform: translate(-50%, -50%);
	width: 500px;
	max-width: 75vw;
  }
  
  .join  input{
	padding: 15px;
	font-size: 1rem;
	border-width: 1px;
	border-color: #38373A;
	border-style: solid;
	width: 80%;
	display: block;
	margin:  50px auto;
  }
  
  .join  button{
	min-width: 200px;
	padding: 12px 0;
	text-align: center;
	background-color: #38373A;
	color: #F7F7F7;
	border-width: 1px;
	border-color: #F7F7F7;
	border-style: solid;
	font-size: 1rem;
	font-weight: 400;
	cursor: pointer;
	display: block;
	margin: 0 auto;
  }

  .maps{
	width: 100%;
	height: 500px;
  }
  
  .footer {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-top: 40px;
	border-top: 1px solid #eee;
  }
  .errorMassage{
	color: red !important;
  }



  .new-loader {
	position: relative;
	width: 2.5em;
	height: 2.5em;
	transform: rotate(165deg);
   }
   
   .new-loader:before, .new-loader:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 0.5em;
	height: 0.5em;
	border-radius: 0.25em;
	transform: translate(-50%, -50%);
   }
   
   .new-loader:before {
	animation: before8 2s infinite;
   }
   
   .new-loader:after {
	animation: after6 2s infinite;
   }
   
   @keyframes before8 {
	0% {
	 width: 0.5em;
	 box-shadow: 1em -0.5em #4d69fa, -1em 0.5em #4d69fa;
	}
   
	35% {
	 width: 2.5em;
	 box-shadow: 0 -0.5em #4d69fa, 0 0.5em #4d69fa;
	}
   
	70% {
	 width: 0.5em;
	 box-shadow: -1em -0.5em #4d69fa, 1em 0.5em #4d69fa;
	}
   
	100% {
	 box-shadow: 1em -0.5em #4d69fa, -1em 0.5em #4d69fa;
	}
   }
   
   @keyframes after6 {
	0% {
	 height: 0.5em;
	 box-shadow: 0.5em 1em #4d69fa, -0.5em -1em #4d69fa;
	}
   
	35% {
	 height: 2.5em;
	 box-shadow: 0.5em 0 #4d69fa, -0.5em 0 #4d69fa;
	}
   
	70% {
	 height: 0.5em;
	 box-shadow: 0.5em -1em #4d69fa, -0.5em 1em #4d69fa;
	}
   
	100% {
	 box-shadow: 0.5em 1em #4d69fa, -0.5em -1em #4d69fa;
	}
   }
   
   .new-loader {
	position: absolute;
	top: calc(50% - 1.25em);
	left: calc(50% - 1.25em);
   }
   .hide-scrollbar{
	overflow: auto;
   }

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }
  
  .highlight {
 
	font-weight: bold;
  }
  .code-container {
	position: relative;
  
	border-radius: 5px;
	 
	margin: 0 auto;
  }
  
  .copy-button {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px 10px;
	background-color: #333;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	z-index: 1; /* Ensure button is clickable */
  }
  
  .copy-button:hover {
	background-color: #555;
  }
  